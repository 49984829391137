import React from "react";
import UserProfileBanner from "../../assets/userProfilerbanner.jpeg";
import UserDetails from "./UserDetails";
import EnrolledPackages from "./EnrolledPackages";

const UserProfile = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url('${UserProfileBanner}')`,
          backgroundSize: "cover",
          backgroundPosition: "20%",
          fontFamily: "DM Sans",
          fontStyle: "normal",
          height: "300px",
        }}
      ></div>
      <div>
        <UserDetails />
      </div>
      <div className = "px-[20rem] mt-[5rem]">
        <EnrolledPackages />
      </div>
    </div>
  );
};

export default UserProfile;
