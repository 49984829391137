import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { NAVBAR_FREE_LIBRARY_DROPDOWN } from "../../constants/text_constants";
import { ChevronDown } from "lucide-react";
import { Link } from "react-router-dom";

const FreeLibraryDropdown = () => {
  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger className="flex hover:text-[#1ECBDB]">
          Free Library <ChevronDown />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {NAVBAR_FREE_LIBRARY_DROPDOWN.map((ele) => (
            <DropdownMenuItem key={ele?.id}>
              <Link to={ele?.link}>
                <span className="hover:text-[#1ECBDB]">{ele?.title}</span>
              </Link>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default FreeLibraryDropdown;
