import React from "react";

const SectionHeading = ({ heading, headingColor, subHeading }) => {
  return (
    <div
      className="text-4xl flex flex-col items-center my-6"
      style={{
        textAlign: "center",
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal",
        color: headingColor ? headingColor : "white",
      }}
    >
      {heading}
      <div
        className="h-0.5 w-20 mt-2"
        style={{ backgroundColor: headingColor ? headingColor : "white" }}
      ></div>
      {subHeading && <div className = "text-sm my-4 w-[50rem]">{subHeading}</div>}
    </div>
  );
};

export default SectionHeading;
