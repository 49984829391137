import React, { useState } from "react";
import { STUDENT_DETAILS_FORM_HEADER } from "../../constants/text_constants";
import FormHeader from "../Login/FormHeader";
import InputField from "../InputField-Component/InputField";
import SelectField from "./SelectField";
import SubmitButton from "./SubmitButton";
import useCountriesData from "../../utils/use-countriesData";
import useStatesData from "../../utils/use-statesData";
import useCitiesData from "../../utils/use-citiesData";

const StudentDetailsForm = ({ showTitle, btnText }) => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [pinCode, setPinCode] = useState("");
  const countriesData = useCountriesData();
  const statesData = useStatesData(selectedCountry?.id);
  const citiesData = useCitiesData(selectedState?.id);

  return (
    <div
      style={{
        fontFamily: "DM Sans",
        fontStyle: "normal",
        lineHeight: "normal",
      }}
      className="ml-[5rem] mt-[2rem]"
    >
      {showTitle && (
        <div className="ml-[2rem]">
          <FormHeader title={STUDENT_DETAILS_FORM_HEADER} />
        </div>
      )}
      <div className="my-6">
        <div className="grid grid-cols-1 gap-4">
          <InputField
            id={"user-name"}
            type={"text"}
            text={"Full Name"}
            placeHolder={"John Doe"}
            setValue={setUserName}
          />
          <InputField
            id={"user-email"}
            type={"email"}
            text={"Email"}
            placeHolder={"abc@example.com"}
            setValue={setUserEmail}
          />
          <InputField
            id={"user-address"}
            type={"text"}
            text={"Address"}
            placeHolder={
              "Maecenas finibus risus purus, et condimentum arcu viverra"
            }
            setValue={setUserAddress}
          />
        </div>

        <div className="grid grid-cols-2 gap-4 mt-4">
          <SelectField
            text={"Country"}
            value={selectedCountry}
            setValue={setSelectedCountry}
            data={countriesData}
            labelText={"Countries"}
          />
          {selectedCountry && (
            <SelectField
              text={"State"}
              value={selectedState}
              setValue={setSelectedState}
              data={statesData}
              labelText={"States"}
            />
          )}
          {selectedState && (
            <SelectField
              text={"City"}
              value={selectedCity}
              setValue={setSelectedCity}
              data={citiesData}
              labelText={"Cities"}
            />
          )}
          {selectedState && (
            <InputField
              id={"user-address-pincode"}
              type={"text"}
              text={"Pincode"}
              placeHolder={"Pincode"}
              setValue={setPinCode}
            />
          )}
        </div>
        <SubmitButton
          userName={userName}
          userEmail={userEmail}
          userAddress={userAddress}
          country={selectedCountry}
          state={selectedState}
          city={selectedCity}
          pincode={pinCode}
          btnText = {btnText}
        />
      </div>
    </div>
  );
};

export default StudentDetailsForm;
