import React from "react";
import { LANDING_PAGE_HERO_CARDS } from "../../constants/text_constants";

const Herocards = () => {
  return (
    <div className="flex flex-wrap justify-around mt-[1rem] mb-[4rem]">
      {LANDING_PAGE_HERO_CARDS.map((d) => (
        <div className="text-center flex flex-col items-center bg-[#F8FCFD] p-10 rounded-lg hover:bg-gradient-to-r from-cyan-100 to-purple-100 cursor-pointer">
          <img alt={"hero-card" + d?.id} src={d?.img} className = "w-13 h-13 bg-white p-2 rounded-full"/>
          <div
            style={{
              fontFamily: "DM Sans",
              textAlign: "center",
              fontStyle: "normal",
              lineHeight: "normal",
            }}
          >
            <div className="text-[#130C40] text-lg">{d?.title}</div>
            <div className="text-[#3F3D4B] text-medium">{d?.subtitle}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Herocards;
