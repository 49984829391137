import React from "react";
import { Card, CardContent } from "../../components/ui/card";
import { UserRoundPen } from "lucide-react";
import { stripHtml } from "../../utils/util-functions/stripHTML";
import { stripText } from "../../utils/util-functions/stripText";
import StarRatings from "react-star-ratings";
import { Link } from "react-router-dom";

const CourseCard = ({
  name,
  offer_price,
  offer_discount,
  price,
  course_banner,
  title_1,
  value_1,
  title_2,
  value_2,
  title_3,
  value_3,
  title_4,
  value_4,
  rating,
  faculty_name,
  thumbnail,
  description,
  totalRating,
  id,
  exploreMoreLink,
}) => {
  return (
    <div>
      <Card>
        <CardContent
          style={{ fontFamily: "DM Sans" }}
          className="aspect-square p-0"
        >
          {/* section 1 - course banner*/}
          <div>
            <img src={course_banner} alt={name} />
          </div>
          <div className="px-3">
            {/* Section 2 - faculty img + name & star*/}
            <div className="flex justify-between items-center my-2">
              {/* faculty img + name */}
              {(faculty_name || thumbnail) && (
                <div className="flex justify-between items-center">
                  <div>
                    {thumbnail ? (
                      <img
                        src={thumbnail}
                        alt={faculty_name}
                        className="w-10 h-10 rounded-full"
                      />
                    ) : (
                      <UserRoundPen />
                    )}
                  </div>
                  <div className="ml-2">{faculty_name ? faculty_name : ""}</div>
                </div>
              )}
              {/* rating */}
              <div className=" flex flex-col items-end">
                <div>
                  <StarRatings
                    rating={Math.floor(rating)}
                    starRatedColor="#FCB129"
                    numberOfStars={5}
                    starDimension="1.2rem"
                  />
                </div>
                <div className="italic">({totalRating} Ratings)</div>
              </div>
            </div>
            {/* Section 3 - course name */}
            <div className="text-xl" style={{ fontWeight: "600" }}>
              {name}
            </div>
            {/* Section 4 - Course Description */}
            <div className="text-sm my-3">
              {stripText(stripHtml(description), 80)}
            </div>
            {/* Section 5 - Metrics */}
            <div className="flex justify-around border-b border-gray-200 pb-2 text-sm">
              {/* title1 */}
              {value_1 && (
                <div className="flex flex-col items-center">
                  <div className="font-semibold">{value_1}</div>
                  <div className="text-[#82808D]">{title_1}</div>
                </div>
              )}
              {/* title2 */}
              {value_2 && (
                <div className="flex flex-col items-center">
                  <div className="font-semibold">{value_2}</div>
                  <div className="text-[#82808D]">{title_2}</div>
                </div>
              )}
              {/* title3 */}
              {value_3 && (
                <div className="flex flex-col items-center">
                  <div className="font-semibold">{value_3}</div>
                  <div className="text-[#82808D]">{title_3}</div>
                </div>
              )}
              {/* title4 */}
              {value_4 && (
                <div className="flex flex-col items-center">
                  <div className="font-semibold">{value_4}</div>
                  <div className="text-[#82808D]">{title_4}</div>
                </div>
              )}
            </div>
            {/* Section 6 - Button and Price */}
            <div className="flex justify-between items-center my-[1rem]">
              {/* Price */}
              <div className="flex items-center gap-1">
                {/* Discount percentage */}
                <div>
                  {offer_discount !== "0" && offer_discount !== null ? (
                    <div className="rounded-l-full rounded-r-full text-white bg-[#5A277C] p-1 w-10 text-xs text-center">
                      {offer_discount}% off
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="flex flex-col">
                  {/* original price */}
                  {offer_discount !== "0" && offer_discount !== null && (
                    <div className="line-through text-gray-500">₹{price}</div>
                  )}
                  {/* offer_price */}
                  <div className="text-[#5A277C] font-bold">
                    ₹
                    {offer_discount !== "0" && offer_discount !== null
                      ? offer_price
                      : price}
                  </div>
                </div>
              </div>
              {/* buttons */}
              <div className="flex gap-3">
                {/* Explore More Button */}
                <div>
                  <Link to={exploreMoreLink + "/" + id}>
                    <button
                      className="text-[#03B3C3] px-2 py-1 rounded-xl cursor-pointer"
                      style={{
                        backgroundColor: "rgba(30, 203, 219, 0.15)",
                        fontWeight: 700,
                      }}
                    >
                      Explore More
                    </button>
                  </Link>
                </div>
                {/* Buy Now Button */}
                <div>
                  <button
                    className="text-[#5A277C] px-2 py-1 rounded-xl cursor-pointer"
                    style={{
                      backgroundColor: "rgba(90, 39, 124, 0.10)",
                      fontWeight: 700,
                    }}
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            </div>
            <div className="text-white text-[1px]">h</div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CourseCard;
