import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setName,
  setEmail,
  setUserCountry,
  setUserState,
  setUserCity,
  setUserPincode,
  setUserAddress,
} from "../../configs/userSlice";

const SubmitButton = ({
  userName,
  userEmail,
  userAddress,
  country,
  state,
  city,
  pincode,
  btnText,
}) => {
  const user = useSelector((store) => store.user);
  const dispatch = useDispatch();
  function handleClick() {
    console.log(
      userName,
      userEmail,
      userAddress,
      country?.name,
      state?.name,
      city?.name,
      pincode
    );
    dispatch(setName(userName));
    dispatch(setEmail(userEmail));
    dispatch(setUserCountry(country?.name));
    dispatch(setUserState(state?.name));
    dispatch(setUserCity(city?.name));
    dispatch(setUserAddress(userAddress));
    dispatch(setUserPincode(pincode));
    // make an API call
  }
  return (
    <div className="flex items-center justify-center my-7">
      <button
        className="px-5 py-3 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg hover:-translate-y-1 hover:shadow-2xl transition-all w-[27rem]"
        onClick={() => handleClick()}
      >
        <span className="drop-shadow-md">{btnText}</span>
      </button>
    </div>
  );
};

export default SubmitButton;
