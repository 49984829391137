import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    authenticated: true,
    purchasedCourses: 0,
    purchasedBooks: 0,
    purchasedTests: 0,
    userName: "Max",
    purchasesTests: 0,
    purchasedBooks: 0,
    phoneNo: "+91-9XXXX-9XXXX",
    email: "mak@max.com",
    userIcon: null,
    userCountry: null,
    userState: null,
    userCity: null,
    userPinCode: null,
    userAddress: null,
    walletPoints: 0,
  },
  reducers: {
    setAuthentication: (state, action) => {
      state.authenticated = true;
    },
    removeAuthentication: (state, action) => {
      state.authenticated = false;
    },
    setName: (state, action) => {
      state.userName = action?.payload;
    },
    setEmail: (state, action) => {
      state.email = action?.payload;
    },
    setUserCountry: (state, action) => {
      state.userCountry = action?.payload;
    },
    setUserState: (state, action) => {
      state.userState = action?.payload;
    },
    setUserCity: (state, action) => {
      state.userCity = action?.payload;
    },
    setUserPincode: (state, action) => {
      state.userPinCode = action?.payload;
    },
    setUserAddress: (state, action) => {
      state.userAddress = action?.payload;
    },
  },
});

export const {
  setAuthentication,
  removeAuthentication,
  setName,
  setEmail,
  setUserCountry,
  setUserState,
  setUserCity,
  setUserPincode,
  setUserAddress,
} = userSlice.actions;
export default userSlice.reducer;
