import React from "react";
import GoogleIcon from "../../assets/Google.png";

const SignUpGoogleBtn = () => {
  return (
    <div>
      <button className="mt-4 w-full flex items-center justify-center bg-white border border-gray-300 py-2 rounded-full">
        <img src={GoogleIcon} alt="Google" className="w-5 h-5 mr-2" />
        <span>Sign up with Google</span>
      </button>
    </div>
  );
};

export default SignUpGoogleBtn;
