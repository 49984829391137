import React from "react";
import { MoveRight } from "lucide-react";
import PageBanner from "../PageBanner/PageBanner";
import BANNER_IMG from "../../assets/GirlWithLaptop.png";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";

const LegalPageBody = ({ heading, body }) => {
  return (
    <div>
      <PageBanner
        title={heading}
        rightImgSrc={BANNER_IMG}
        backgroundGradient = {'linear-gradient(to right, rgba(254, 246, 250, 0.9), rgba(225, 234, 252, 0.9))'}
        backgroundImage = {BACKGROUND_IMAGE}
        fontColor = {"#130C40"}
        backgroundPosition = {"top"}
      />
      <div
        className="px-[10rem] mt-10 -mb-40"
        style={{ fontFamily: "DM Sans" }}
      >
        {body.map((ele) => (
          <div className="my-8">
            {ele?.title && (
              <div className="text-xl font-bold underline underline-offset-4 mb-2">
                {ele?.title.toUpperCase()}
              </div>
            )}
            {ele?.subtitle && (
              <div className="text-medium">{ele?.subtitle}</div>
            )}
            {ele?.points.length > 0 &&
              ele?.points.map((d) => (
                <div className="my-2 ml-2">
                  {d?.title && (
                    <div className="font-bold">{d?.title.toUpperCase()}</div>
                  )}
                  {d?.point && (
                    <div>
                      <span>
                        <MoveRight className="inline ml-4" /> {d?.point}
                      </span>
                    </div>
                  )}
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LegalPageBody;
