import React from "react";
import { Link } from "react-router-dom";
import CoursesDropdown from "./CoursesDropdown";
import FreeLibraryDropdown from "./FreeLibraryDropdown";
import LoginRegisterButton from "./LoginRegisterButton";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import { useSelector } from "react-redux";
import UserDropdown from "./UserDropdown";

const NavbarRight = () => {
  const user = useSelector((store) => store.user);

  return (
    <div className="flex items-center gap-4">
      <div className="hover:text-[#1ECBDB]">
        <Link to={UNPROTECTED_ROUTES?.home}>Home</Link>
      </div>
      {/* <CoursesDropdown /> */}
      <div className="hover:text-[#1ECBDB]">
        <Link to={UNPROTECTED_ROUTES?.courses}>Courses</Link>
      </div>
      <div className="hover:text-[#1ECBDB]">
        <Link to={UNPROTECTED_ROUTES?.testSeries}>Test Series</Link>
      </div>
      <div className="hover:text-[#1ECBDB]">
        <Link to={UNPROTECTED_ROUTES?.books}>Books</Link>
      </div>
      <FreeLibraryDropdown />
      {!user?.authenticated ? (
        <LoginRegisterButton />
      ) : (
        <UserDropdown userName={user?.userName} />
      )}
    </div>
  );
};

export default NavbarRight;
