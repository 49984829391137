import React from "react";
import { TESTS_PAGE_TESTS_LIST_TAB_HEADINGS } from "../../constants/text_constants";
import { useSelector } from "react-redux";

const TestsListHeader = ({ setShowAllTests, showAllTests }) => {
  const cache = useSelector((store) => store.cache.allTestsCache);
  const user = useSelector((store) => store.user);

  return (
    <div
      className="flex justify-between border-b border-gray-200 pb-2 text-[#3F3D4B]"
      style={{ fontFamily: "DM Sans" }}
    >
      <div className="flex gap-5 cursor-pointer">
        <div
          className={
            showAllTests
              ? "text-[#03B3C3] underline underline-offset-[0.9rem] font-semibold"
              : ""
          }
          onClick={() => setShowAllTests(true)}
        >
          {TESTS_PAGE_TESTS_LIST_TAB_HEADINGS[0]}
        </div>
        <div
          className={
            !showAllTests
              ? "text-[#03B3C3] underline underline-offset-[0.9rem] font-semibold"
              : ""
          }
          onClick={() => setShowAllTests(false)}
        >
          {TESTS_PAGE_TESTS_LIST_TAB_HEADINGS[1]}
        </div>
      </div>
      {user?.authenticated ? (
        showAllTests ? (
          <div>Total Tests : {cache[0]?.total}</div>
        ) : (
          <div>Purchased Tests : {user?.purchasesTests}</div>
        )
      ) : !showAllTests ? (
        <div>Purchased Tests : 0</div>
      ) : (
        <div>Total Tests : {cache[0]?.total}</div>
      )}
    </div>
  );
};

export default TestsListHeader;
