import { useState, useEffect } from "react";

const useCountriesData = () => {
  const [countriesData, setCountriesData] = useState(null);
  const fetchCountriesData = async () => {
    const response = await fetch(process.env.REACT_APP_RANK_PLUS_COUNTRIES);
    const json = await response.json();
    setCountriesData(json?.data?.countries);
  };

  useEffect(() => {
    fetchCountriesData();
  }, []);

  return countriesData;
};

export default useCountriesData;
