import { useState, useEffect } from "react";

const useCourseHeaders = (courseId) => {
  const [courseHeaders, setCourseHeaders] = useState(null);
  const fetchCourseHeadersData = async () => {
    try {
      const requestOptions = {
        method: "GET",
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_COURSE_DETAILS_HEADER.replace(
          /COURSE_ID/,
          courseId
        ),
        requestOptions
      );
      const json = await response.json();
      setCourseHeaders(json?.data);
    } catch (err) {
      console.log("Error fetchCourseHeadersData", err);
    }
  };

  useEffect(() => {
    fetchCourseHeadersData();
  }, [courseId]);

  return courseHeaders;
};

export default useCourseHeaders;
