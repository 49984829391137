import React from "react";
import { USER_DETAILS_PAGE_ENROLLED_PACKAGES_HEADING } from "../../constants/text_constants";

const EnrolledPackages = () => {
  return (
    <div>
      <div className = "text-2xl font-semibold">{USER_DETAILS_PAGE_ENROLLED_PACKAGES_HEADING}</div>
    </div>
  );
};

export default EnrolledPackages;
