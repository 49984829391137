import React, { useState } from "react";
import NumberField from "./NumberField";
import FormHeader from "./FormHeader";
import OTPField from "./OTPField";
import SendOTPButton from "./SendOTPButton";
import SignUpGoogleBtn from "./SignUpGoogleBtn";
import SignUpField from "./SignUpField";
import { toast } from "sonner";
import {
  LOGIN_FORM_TITLE,
  LOGIN_FORM_SUB_TITLE,
  LOGIN_FORM_RESEND_OTP_LIMIT,
} from "../../constants/text_constants";
import SignInButton from "./SignInButton";
import SignUpTermsCondition from "./SignUpTermsCondition";
import ResendOTPButton from "./ResendOTPButton";
import { useHashdedOTP } from "../../utils/getHashdedOTP";

const LoginPageRightForm = () => {
  const [mobileNo, setMobileNo] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [tnc, setTnc] = useState(false);
  const [otpToast, setOtpToast] = useState(false);
  const [resendOtpBtnClick, setResendOtpBtnClick] = useState(0);
  // const hashedResponse = useHashdedOTP(mobileNo);
  const hashedResponse = ""

  if (showOtp && otpToast) {
    toast(`An OTP has been sent to ${mobileNo}`);
    setOtpToast(false);
  }

  return (
    <div
      style={{
        fontFamily: "DM Sans",
        fontStyle: "normal",
        lineHeight: "normal",
      }}
      className="ml-[3rem] mt-[5rem]"
    >
      <FormHeader title={LOGIN_FORM_TITLE} subtitle={LOGIN_FORM_SUB_TITLE} />
      <NumberField
        setMobileNo={setMobileNo}
        mobileNo={mobileNo}
        showEdit={mobileNo.length === 10}
        setShowOtp={setShowOtp}
        setOtp={setOtp}
      />
      {mobileNo.length === 10 && showOtp && (
        <div className="flex items-center justify-between">
          <div>
            <OTPField otp={otp} setOtp={setOtp} />
          </div>
          {resendOtpBtnClick < LOGIN_FORM_RESEND_OTP_LIMIT && (
            <div>
              <ResendOTPButton
                resendOtpBtnClick={resendOtpBtnClick}
                setResendOtpBtnClick={setResendOtpBtnClick}
              />
            </div>
          )}
        </div>
      )}
      {/* Terms and Condition */}
      {mobileNo.length === 10 && showOtp && (
        <SignUpTermsCondition setTnc={setTnc} />
      )}
      {(mobileNo.length !== 10 || showOtp === false) && (
        <SendOTPButton
          setShowOtp={setShowOtp}
          mobileNo={mobileNo}
          setOtpToast={setOtpToast}
        />
      )}
      {mobileNo.length === 10 && showOtp && (
        <SignInButton otp={otp} hashedResponse={hashedResponse} tnc={tnc} />
      )}

      <div className="mt-6 flex items-center justify-between">
        <hr className="flex-grow border-gray-300" />
        <span className="mx-4 text-sm text-gray-500">OR</span>
        <hr className="flex-grow border-gray-300" />
      </div>
      <SignUpGoogleBtn />
      {/* <SignUpField /> */}
    </div>
  );
};

export default LoginPageRightForm;
