import { Outlet, useLocation } from "react-router-dom";
import "./App.css";
import Navbar from "./app_components/Navbar/Navbar";
import { Toaster } from "./components/ui/sonner";
import Footer from "./app_components/Footer/Footer";
import { UNPROTECTED_ROUTES } from "./routes/routes";

function App() {
  const location = useLocation();

  return (
    <div className="App">
      {location.pathname !== UNPROTECTED_ROUTES?.login &&
        location.pathname !== UNPROTECTED_ROUTES?.studentDetails && <Navbar />}
      <Outlet />
      <Toaster />
      {location.pathname !== UNPROTECTED_ROUTES?.login &&
        location.pathname !== UNPROTECTED_ROUTES?.studentDetails && <Footer />}
    </div>
  );
}

export default App;
