import React from "react";
import { useSelector } from "react-redux";
import InValidLottie from "../Lottie-Components/InValidLottie";

const MyTestsList = () => {
  const user = useSelector((store) => store.user.authenticated);

  // user has not signed in
  if (!user) {
    return <InValidLottie text={"You Need to sign in to see your Tests packages"} />;
  }

  // user has signed in but doesnot has any courses

  return <div>My Tests</div>;
};

export default MyTestsList;
