import React, { useState, useEffect } from "react";
import useCourseDescription from "../../utils/use-CourseDescription";
import { COURSE_DETAILS_DESCRIPTION_HEADING } from "../../constants/text_constants";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";

const CourseDescriptionTab = ({ courseId }) => {
  const courseDescription = useCourseDescription(courseId);
  const [courseFaq, setCourseFaq] = useState([]);

  const fetchCourseFAQs = async () => {
    try {
      const requestOptions = {
        method: "POST",
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_COURSE_DETAILS_FAQ +
          `limit=20&page=0&type=course&id=${courseId}`,
        requestOptions
      );
      const json = await response.json();
      console.log(json?.data);
      setCourseFaq(json?.data?.faqs);
    } catch (err) {
      console.log("Error fetchCourseFAQs", err);
    }
  };

  useEffect(() => {
    fetchCourseFAQs();
  }, [courseId]);

  return (
    <div style={{ fontFamily: "DM Sans" }}>
      {courseDescription?.details.map((ele) => (
        <div key={ele?.faq_package_id}>{ele?.description}</div>
      ))}
      <div className="text-2xl font-bold mt-10 mb-5">
        {COURSE_DETAILS_DESCRIPTION_HEADING}
      </div>
      <div>
        {courseFaq?.map((ele) => (
          <div key={ele?.id}>
            <Accordion type="single" collapsible>
              <AccordionItem value="item-1">
                <AccordionTrigger>{ele?.question}</AccordionTrigger>
                <AccordionContent>{ele?.answer}</AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseDescriptionTab;
