import React, { useState } from "react";
import SectionHeading from "./SectionHeading";
import { LANDING_PAGE_COURSES_AVAILABLE } from "../../constants/text_constants";
import CourseCard from "./CourseCard";

const CoursesAvailableSection = ({ data }) => {
  const [show, setShow] = useState(false);

  if (!data || data?.length === 0) return <div></div>;

  const displayCards = data.slice(0, 6);
  const remainingCards = data.slice(6, 8);

  return (
    <div className="my-[6rem]">
      <SectionHeading
        heading={LANDING_PAGE_COURSES_AVAILABLE?.heading}
        subHeading={LANDING_PAGE_COURSES_AVAILABLE?.subHeading}
        headingColor={"#130C40"}
      />
      <div className="flex justify-center items-center mt-[1rem]">
        <div className="grid grid-cols-3 gap-[2rem]">
          {displayCards.map((ele) => (
            <CourseCard id={ele?.id} {...ele} />
          ))}
          {show &&
            remainingCards.map((ele) => <CourseCard id={ele?.id} {...ele} />)}
        </div>
      </div>
      {data?.length > 6 && (
        <div className="flex justify-center items-center mt-16">
          <button
            className="px-5 py-1 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg hover:-translate-y-1 hover:shadow-2xl transition-all"
            onClick={() => setShow(!show)}
          >
            <span className="drop-shadow-md">
              {show ? "Close" : "View All Courses"}
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default CoursesAvailableSection;
