import React, { useState, useEffect } from "react";
import { LOGIN_FORM_RESEND_OTP_TIMER } from "../../constants/text_constants";

const ResendOTPButton = ({ resendOtpBtnClick, setResendOtpBtnClick}) => {
  const [seconds, setSeconds] = useState(LOGIN_FORM_RESEND_OTP_TIMER);
  const [activeBtn, setActiveBtn] = useState(false);

  function handleClick() {
    if (!activeBtn) return;
    if (activeBtn) {
      setResendOtpBtnClick(resendOtpBtnClick + 1);
      setActiveBtn(false);
      setSeconds(LOGIN_FORM_RESEND_OTP_TIMER);
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(intervalId);
        setActiveBtn(true);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds, resendOtpBtnClick]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return (
    <div
      style={{
        backgroundImage: activeBtn
          ? "linear-gradient(to right, rgba(30, 203, 219, 1) , rgba(111, 49, 153, 1))"
          : "linear-gradient(to right, rgba(111, 49, 153, 0.5), rgba(30, 203, 219, 0.5))",
        cursor: activeBtn ? "pointer" : "wait",
      }}
      className="py-1 px-2 rounded-lg text-white font-bold shadow-lg"
      onClick={handleClick}
    >
      <div>Resend OTP</div>
      <div className="flex flex-col items-center">
        {minutes}:
        {remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}
      </div>
    </div>
  );
};

export default ResendOTPButton;
