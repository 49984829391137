import React from "react";
import RankPlusLogo from "../../assets/RankPlus_logo.png";
import RankPlusLogoText from "../../assets/RankPlus_logo_text.png";
import SearchBar from "./SearchBar";

const NavbarLeft = () => {
  return (
    <div className="flex items-center">
      <img
        alt="rank-plus-logo"
        src={RankPlusLogo}
        className="w-[60px] h-[64px]"
      />
      <img
        alt="rank-plus-logo-text"
        src={RankPlusLogoText}
        className="w-[127px] h-[30px] ml-4"
      />
      <SearchBar/>
    </div>
  );
};

export default NavbarLeft;
