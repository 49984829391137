import React from "react";
import { verifyOTP } from "../../utils/util-functions/verifyOTP";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const SignInButton = ({ otp, hashedResponse, tnc }) => {
  const navigate = useNavigate();
  function handleSignIn() {
    if (otp.length === 6 && tnc) {
      if (verifyOTP(otp, hashedResponse)) {
        // make the user authenticated

        // make a call to api to check if student details are full
        // if yes it is full then redirect to /
        // else
        navigate(UNPROTECTED_ROUTES?.studentDetails);
      } else {
        toast("incorrect OTP");
      }
    }
    if (!tnc) {
      toast("Click on tnc");
    }
    if (otp.length < 6) {
      toast("otp is less than 6 digits");
    }
  }

  return (
    <div className="flex items-center justify-center my-7">
      <button
        className="px-5 py-3 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg hover:-translate-y-1 hover:shadow-2xl transition-all w-[27rem]"
        onClick={handleSignIn}
      >
        <span className="drop-shadow-md">Sign In</span>
      </button>
    </div>
  );
};

export default SignInButton;
