import React from 'react'

const FooterBottomCol1 = ({data}) => {
  return (
    <div>
      <div className = "flex gap-5 items-center justify-center">
        <div><img alt = {"rankplus-logo"} src = {data?.logo1}/></div>
        <div><img alt = {"rankplus-logo-text"} src = {data?.logo2}/></div>
      </div>
      <div className = "text-[#9591AA] text-center text-sm mt-3 w-[18rem]">
        {data?.desc}
      </div>
    </div>
  )
}

export default FooterBottomCol1
