import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setStateCache } from "../configs/cache";

const useStatesData = (countryId) => {
  const [statesData, setStatesData] = useState(null);
  const cache = useSelector((store) => store.cache.stateCache);
  const dispatch = useDispatch();

  const fetchStatesData = async () => {
    const response = await fetch(
      process.env.REACT_APP_RANK_PLUS_STATES + countryId
    );
    const json = await response.json();
    setStatesData(json?.data?.states);
    dispatch(setStateCache([json?.data?.states, countryId]));
  };

  useEffect(() => {
    if (cache.countryId) {
      return cache.countryId;
    } else {
      fetchStatesData();
    }
  }, [countryId]);

  return statesData;
};

export default useStatesData;
