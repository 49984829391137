import React from "react";
import ScrollCarousel from "scroll-carousel-react";

const FeaturesScrollCards = ({ data }) => {
  return (
    <div className = "mt-5">
      <ScrollCarousel autoplay autoplaySpeed={8} speed={7}>
        {data.map((ele) => (
          <div
            key={ele?.id}
            className="text-center flex flex-col items-center bg-[#F8FCFD] p-5 rounded-lg hover:bg-gradient-to-r from-cyan-500 to-purple-500 cursor-pointer h-[10rem] w-[10rem] mx-1"
          >
            <img
              alt={"hero-card" + ele?.id}
              src={ele?.img}
              className="w-[5rem] h-[5rem] bg-white p-2 rounded-full"
            />
            <div
              style={{
                fontFamily: "DM Sans",
                textAlign: "center",
                fontStyle: "normal",
                lineHeight: "normal",
              }}
            >
              <div className="text-[#130C40] text-sm mt-1">{ele?.desc}</div>
            </div>
          </div>
        ))}
      </ScrollCarousel>
    </div>
  );
};

export default FeaturesScrollCards;
