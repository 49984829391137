import React from "react";
import PageBanner from "../PageBanner/PageBanner";
import BANNER_IMG from "../../assets/Doctor.png";
import {
  LANDING_PAGE_BANNER_1,
  LANDING_PAGE_BANNER_2,
} from "../../constants/text_constants";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import BANNER_IMG_1 from "../../assets/RankPlusTeacher.png";

const PromoCode = () => {
  return (
    <span
      className="text-2xl p-2"
      style={{ borderRadius: "10px", border: "1px dashed #FFF" }}
    >
      {LANDING_PAGE_BANNER_1?.subtitle}
    </span>
  );
};

const ExploreMoreBtn = () => {
  return (
    <div className="flex items-center justify-start">
      <button className="px-5 py-1 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg hover:-translate-y-1 hover:shadow-2xl transition-all">
        <span className="drop-shadow-md">Explore More</span>
      </button>
    </div>
  );
};

const HeroBanner = () => {
  return (
    <div>
      <PageBanner
        title={LANDING_PAGE_BANNER_1?.title1}
        title2={LANDING_PAGE_BANNER_1?.title2}
        rightImgSrc={BANNER_IMG}
        backgroundGradient={
          "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#FFF"}
        component={<PromoCode />}
        backgroundPosition={"top"}
      />
      <PageBanner
        title={LANDING_PAGE_BANNER_2?.title1}
        title2={LANDING_PAGE_BANNER_2?.title2}
        subtitle={LANDING_PAGE_BANNER_2?.subtitle}
        rightImgSrc={BANNER_IMG_1}
        backgroundGradient={
          "linear-gradient(to right, rgba(254, 246, 250, 0.9), rgba(225, 234, 252, 0.9))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#130C40"}
        backgroundPosition={"center"}
        // component={<ExploreMoreBtn />}
      />
    </div>
  );
};

export default HeroBanner;
