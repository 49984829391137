import React from "react";
import REVOLUTION_LEARNING_BG from "../../assets/revolution_learning_bg.png";
import { LANDING_PAGE_REVOLUTION_LEARNING_YT_VIDEO } from "../../constants/video_constants";
import SectionHeading from "./SectionHeading";
import { LANDING_PAGE_REVOLUTION } from "../../constants/text_constants";
import DOWNLOAD_ICON from "../../assets/DownLoadIcon.png";
import { TOP_FOOTER } from "../../constants/text_constants";
import { Link } from "react-router-dom";

const RevolutionLearning = () => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to right, rgba(34, 211, 238, 0.5), rgba(147, 51, 234, 0.5)), url(${REVOLUTION_LEARNING_BG})`,
        height: "33rem",
        backgroundSize: "cover",
        backgroundPosition: "center",
        fontFamily: "DM Sans",
      }}
      className="rounded-xl flex flex-col items-center my-5"
    >
      <div className="flex justify-center items-center">
        <SectionHeading heading={LANDING_PAGE_REVOLUTION?.heading} />
      </div>
      <div className="flex items-center justify-center bg-white w-[70rem] p-5 rounded-lg gap-5 mt-5">
        <div className="p-2 shadow-lg">
          <LANDING_PAGE_REVOLUTION_LEARNING_YT_VIDEO />
        </div>

        <div className="text-sm flex flex-col gap-5">
          <div>{LANDING_PAGE_REVOLUTION?.desc1}</div>
          <div>{LANDING_PAGE_REVOLUTION?.desc2}</div>
          <Link to={TOP_FOOTER?.playStoreLink} target="_blank">
            <div className="flex items-center justify-start">
              <button className="px-5 py-1 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg hover:-translate-y-1 hover:shadow-2xl transition-all">
                <div className="drop-shadow-md flex items-center gap-2">
                  {LANDING_PAGE_REVOLUTION?.buttonText}
                  <img alt="download-icon" src={DOWNLOAD_ICON} />
                </div>
              </button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RevolutionLearning;
