import React from "react";
import { useSelector } from "react-redux";
import InValidLottie from "../Lottie-Components/InValidLottie";

const MyCoursesList = () => {
  const user = useSelector((store) => store.user.authenticated);

  // user has not signed in
  if (!user) {
    return <InValidLottie text={"You Need to sign in to see your Courses"} />;
  }

  // user has signed in but doesnot has any courses
  

  return <div>My Courses</div>;
};

export default MyCoursesList;
