import React, { useState } from "react";
import useCourseHeaders from "../../utils/use-CourseHeaders";
import { useParams } from "react-router-dom";
import useCourseSubjects from "../../utils/use-CourseSubjects";
import DescriptionBanner from "../PageBanner/DescriptionBanner";
import TabHeadings from "./TabHeadings";
import CourseDescriptionTab from "./CourseDescriptionTab";
import LiveClassesTab from "./LiveClassesTab";
import ClassroomTab from "./ClassroomTab";
import DPPTab from "./DPPTab";
import TestSeriesTab from "./TestSeriesTab";
import AnnouncementTab from "./AnnouncementTab";

const CourseDetails = () => {
  const { courseId } = useParams();
  const courseHeaders = useCourseHeaders(courseId);
  const courseSubjects = useCourseSubjects(courseId);
  const [index, setIndex] = useState(0);

  return (
    <div>
      <DescriptionBanner courseHeaders={courseHeaders} />
      <TabHeadings index={index} setIndex={setIndex} />
      <div className="mx-[5rem] my-[2rem] w-[52rem]">
        {index === 0 && <CourseDescriptionTab courseId={courseId} />}
        {index === 1 && <LiveClassesTab courseId={courseId} />}
        {index === 2 && <ClassroomTab />}
        {index === 3 && <DPPTab />}
        {index === 4 && <TestSeriesTab />}
        {index === 5 && <AnnouncementTab />}
      </div>
    </div>
  );
};

export default CourseDetails;
