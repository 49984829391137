import { useState, useEffect } from "react";

export const useHashdedOTP = (mobileNo) => {
  const [hashedResponse, setHashedResponse] = useState(null);

  const fetchResponse = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };
    const response = await fetch(
      process.env.REACT_APP_RANK_PLUS_SEND_OTP + "=" + mobileNo,
      requestOptions
    );
    const json = await response.json();
    console.log(json);
  };

  useEffect(() => {
    if (mobileNo.length === 10) {
      fetchResponse();
    }
  }, [mobileNo]);

  return hashedResponse;
};
