import { useState, useEffect } from "react";

const useCourseSubjects = (courseId) => {
  const [couseSubjects, setCourseSubjects] = useState(null);
  const fetchCourseSubjects = async () => {
    try {
      const requestOptions = {
        method: "POST",
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_COURSE_DETAILS_SUBJECTS +
          `course_id=${courseId}&limit=10&page=0`,
        requestOptions
      );
      const json = await response.json();
      setCourseSubjects(json?.data);
    } catch (err) {
      console.log("Error useCourseSubjects ", err);
    }
  };

  useEffect(() => {
    fetchCourseSubjects();
  }, [courseId]);

  return couseSubjects;
};

export default useCourseSubjects;
