import React from "react";
import { ScrollArea } from "../../components/ui/scroll-area";
import SectionHeading from "./SectionHeading";
import { LANDING_PAGE_PREPERATION_SECTION_TESTS } from "../../constants/text_constants";
import TestCard from "./TestCard";
import { Link } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const PreperationSectionTest = ({ tests }) => {
  return (
    <div className="flex flex-col items-center my-[2rem]">
      <SectionHeading
        heading={LANDING_PAGE_PREPERATION_SECTION_TESTS?.heading}
        subHeading={LANDING_PAGE_PREPERATION_SECTION_TESTS?.subHeading}
        headingColor={"#130C40"}
      />
      <ScrollArea className="h-[40rem] w-[30rem] rounded-md border p-4">
        {tests.map((ele) => (
          <TestCard key={ele?.id} {...ele} />
        ))}
      </ScrollArea>
      <div className="flex justify-center items-center mt-16">
        <Link to={UNPROTECTED_ROUTES?.testSeries}>
          <button className="px-5 py-1 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg hover:-translate-y-1 hover:shadow-2xl transition-all">
            <span className="drop-shadow-md">View All</span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default PreperationSectionTest;
