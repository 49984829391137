
import React from "react";

export const LANDING_PAGE_REVOLUTION_LEARNING_YT_VIDEO = () => {
  return (
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/VLOY90oFwpA?si=hl-25xi6VdEacG-e"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>
  );
};
