import React from "react";
import PageBanner from "../PageBanner/PageBanner";
import { BLOGS_PAGE_BANNER } from "../../constants/text_constants";
import BANNER_IMG from "../../assets/GirlWithLaptop.png";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";

const BlogsPage = () => {
  return (
    <div>
      <PageBanner
        title={BLOGS_PAGE_BANNER?.title1}
        subtitle={BLOGS_PAGE_BANNER?.subtitle}
        rightImgSrc={BANNER_IMG}
        backgroundGradient={
          "linear-gradient(to right, rgba(254, 246, 250, 0.9), rgba(225, 234, 252, 0.9))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#130C40"}
      />
    </div>
  );
};

export default BlogsPage;
