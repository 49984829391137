import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "../../components/ui/dropdown-menu";
import { NAVBAR_USER_AUTHENTICATED_DROPDOWN } from "../../constants/text_constants";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../components/ui/avatar";
import { UserRoundPen } from "lucide-react";
import LOGOUT from "../../assets/SignOut.png";
import { useDispatch } from "react-redux";
import { removeAuthentication } from "../../configs/userSlice";
import { Link } from "react-router-dom";

const UserDropdown = ({ userName }) => {
  const dispatch = useDispatch();

  function handleLogout() {
    dispatch(removeAuthentication());
  }
  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger className="flex hover:text-[#1ECBDB]">
          <Avatar>
            <AvatarImage src="https://github.com/shadcn.png" />
            <AvatarFallback>
              <UserRoundPen />
            </AvatarFallback>
          </Avatar>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <div className="flex justify-center">
            <div>
              <DropdownMenuItem>
                <span className="font-bold mr-1">{userName}</span> 👋🏻
              </DropdownMenuItem>
            </div>
          </div>
          <DropdownMenuSeparator />

          {NAVBAR_USER_AUTHENTICATED_DROPDOWN.map((ele) => (
            <DropdownMenuItem key={ele?.id}>
              <Link to={ele?.link}>
                <div className="flex justify-between items-center gap-1 hover:text-[#1ECBDB]">
                  <div className="w-[1.3rem] h-[1.3rem]">
                    <img alt={ele?.title} src={ele?.imgSrc} />
                  </div>
                  <div>{ele?.title}</div>
                </div>
              </Link>
            </DropdownMenuItem>
          ))}
          <DropdownMenuItem>
            <div
              className="flex justify-between items-center gap-1 hover:text-[#1ECBDB]"
              onClick={handleLogout}
            >
              <div className="w-[1.3rem] h-[1.3rem]">
                <img alt="logout" src={LOGOUT} />
              </div>
              <div>Logout</div>
            </div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default UserDropdown;
