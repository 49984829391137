import { useState, useEffect } from "react";

const useLandingPageData = () => {
  const [landingPageData, setLandingPageData] = useState(null);

  const fetchLandingPageData = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_LANDING_PAGE_DATA
      );
      const json = await response.json();
      setLandingPageData(json);
    } catch (err) {
      console.error("Error useLandingPageData : ", err);
    }
  };

  useEffect(() => {
    fetchLandingPageData();
  }, []);

  return landingPageData;
};

export default useLandingPageData;
