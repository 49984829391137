import React from "react";
import { LANDING_PAGE_METRICS } from "../../constants/text_constants";
import SectionHeading from "./SectionHeading";

const MetricsSection = () => {
  return (
    <div className="flex flex-col items-center my-[6rem]">
      <SectionHeading
        heading={LANDING_PAGE_METRICS?.heading}
        headingColor={"#130C40"}
        subHeading={LANDING_PAGE_METRICS?.subHeading}
      />
      <div
        className="flex flex-wrap justify-center gap-[5rem]"
        style={{
          fontFamily: "DM Sans",
          fontStyle: "normal",
          lineHeight: "normal",
        }}
      >
        {LANDING_PAGE_METRICS?.metrics.map((ele) => (
          <div
            id={ele?.id}
            className="bg-[#F8FCFD] p-7 rounded-lg flex flex-col items-center hover:bg-gradient-to-r from-cyan-100 to-purple-100"
          >
            <div className="text-[#5A277C] font-bold text-5xl">
              {ele?.stats}
            </div>
            <div className="w-20 h-0.5 bg-gray-100 my-2"></div>
            <div className="flex gap-5 justify-center items-center">
              <div>
                <img alt={ele?.desc} src={ele?.img} className="w-8 h-8" />
              </div>
              <div>{ele?.desc}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MetricsSection;
