import { configureStore } from "@reduxjs/toolkit";
import cacheSlice from "./cache";
import userSlice from "./userSlice";

const appStore = configureStore({
  reducer: {
    cache: cacheSlice,
    user: userSlice,
  },
});

export default appStore;
