import React from 'react'
import LoginPageLeft from '../Login/LoginPageLeft'
import StudentDetailsRight from './StudentDetailsRight'

const StudentDetails = () => {
  return (
    <div className = "p-4 flex gap-10">
      <LoginPageLeft/>
      <StudentDetailsRight/>
    </div>
  )
}

export default StudentDetails
