import React from 'react'

const BookDetails = () => {
  return (
    <div>
      Book Details
    </div>
  )
}

export default BookDetails
