
export const UNPROTECTED_ROUTES = {
    terms : "/terms_and_conditions",
    privacy : "/privacy_policy",
    return : "/return_and_refund",
    home : "/",
    login : "/login",
    studentDetails : "/student_details",
    testSeries : "/test-series",
    courses : "/courses",
    books : "/books",
    blogs : "/blogs",
    courseDetails : "/course_details",
    testDetails : "/test_details",
    bookDetails : "/book_details",
    freeLibrary : "/library",
    freeLibraryVideos : "/library/videos",
    freeLibraryNotes : "/library/notes",
    freeLibraryTests : "/library/tests"
}

export const PROTECTED_ROUTES = {
    profile : "/profile"
}