import React from "react";
import PageBanner from "../PageBanner/PageBanner";
import { BOOKS_PAGE_BANNER, BOOKS_PAGE_SHOW_BOOKS_FILTER } from "../../constants/text_constants";
import BANNER_IMG from "../../assets/GirlWithLaptop.png";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import BooksList from "./BooksList";

const BooksPage = () => {
  return (
    <div>
      <PageBanner
        title={BOOKS_PAGE_BANNER?.title1}
        subtitle={BOOKS_PAGE_BANNER?.subtitle}
        rightImgSrc={BANNER_IMG}
        backgroundGradient={
          "linear-gradient(to right, rgba(254, 246, 250, 0.9), rgba(225, 234, 252, 0.9))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#130C40"}
      />
      <div className="mx-[10rem] flex gap-[2rem] justify-around my-[3rem]">
        {BOOKS_PAGE_SHOW_BOOKS_FILTER && (
          <div className="bg-green-300 w-1/4">filter</div>
        )}
        <div className="w-3/4">
          <BooksList/>
        </div>
      </div>
    </div>
  );
};

export default BooksPage;
