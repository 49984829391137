import React, { useState, useEffect } from "react";
import PageBanner from "../PageBanner/PageBanner";
import { FREE_LIBRARY_TESTS_BANNER } from "../../constants/text_constants";
import BANNER_IMG from "../../assets/GirlWithLaptop.png";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import TabHeadings from "./TabHeadings";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_LIMIT } from "../../constants/text_constants";
import { setFreeLibraryTestsCache } from "../../configs/cache";
import LoadingLottie from "../Lottie-Components/LoadingLottie";
import { Card, CardContent } from "../../components/ui/card";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { toast } from "sonner";

const FreeLibraryTests = () => {
  const [libraryTestsData, setLibraryTestsData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const cache = useSelector((store) => store.cache.freeLibraryTestsCache);

  const fetchFreeLibraryTests = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_FREE_LIBRARY_TESTS + currentPage
      );
      const json = await response.json();
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
      setLibraryTestsData(json?.data);
      dispatch(setFreeLibraryTestsCache([json?.data, currentPage]));
    } catch (err) {
      console.error("Error fetchFreeLibraryTests", err);
    }
  };

  useEffect(() => {
    if (cache[currentPage]) {
      setLibraryTestsData(cache[currentPage]);
      setTotalPages(Math.ceil(cache[currentPage]?.total / 12));
    } else {
      fetchFreeLibraryTests();
    }
  }, [currentPage, cache]);

  return (
    <div>
      <PageBanner
        title={FREE_LIBRARY_TESTS_BANNER?.title1}
        subtitle={FREE_LIBRARY_TESTS_BANNER?.subtitle}
        rightImgSrc={BANNER_IMG}
        backgroundGradient={
          "linear-gradient(to right, rgba(254, 246, 250, 0.9), rgba(225, 234, 252, 0.9))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#130C40"}
      />
      <div className="mx-[10rem]">
        <TabHeadings
          index={2}
          text={"Total Tests"}
          total={libraryTestsData?.total}
        />
      </div>
      <div>
        {(!libraryTestsData || libraryTestsData?.tests?.length === 0) && (
          <LoadingLottie />
        )}
      </div>
      <div className="grid grid-cols-3 mx-[10rem] gap-2 mt-10">
        {libraryTestsData?.tests.map((ele) => (
          <div key={ele?.id}>
            <Card>
              <CardContent style={{ fontFamily: "DM Sans" }} className="p-2">
                <div className="flex gap-4 items-center">
                  <div>
                    <img
                      src={ele?.thumbnail}
                      alt={ele?.name}
                      className="w-[5rem] h-[5rem] rounded-lg"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div>{ele?.name}</div>
                    <div className="text-[#1ECBDB] font-semibold">
                      {ele?.dpp_test_count} Tests
                    </div>
                    <div>
                      <button
                        className="border-2 border-[#5A277C] px-2 py-1 rounded-xl hover:bg-[#5A277C] hover:text-white"
                        onClick={() =>
                          toast(
                            `Please log in to RanKplus Mobile App to access the Test`
                          )
                        }
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
      <div className="p-10 flex items-center justify-center">
        {currentPage > 0 && (
          <ChevronLeft
            className="mx-2 hover:-translate-x-1 transition-all cursor-pointer"
            onClick={() => setCurrentPage((currentPage) => currentPage - 1)}
          />
        )}

        {[
          ...Array(totalPages)
            .keys()
            .map((p) => (
              <span
                className={
                  "mx-2 cursor-pointer" +
                  (p === currentPage ? " font-bold text-lg" : "")
                }
                onClick={() => setCurrentPage((currentPage) => p)}
              >
                {p + 1}
              </span>
            )),
        ]}
        {currentPage < totalPages - 1 && (
          <ChevronRight
            className="mx-2 hover:translate-x-1 transition cursor-pointer"
            onClick={() => setCurrentPage((currentPage) => currentPage + 1)}
          />
        )}
      </div>
    </div>
  );
};

export default FreeLibraryTests;
