import React from "react";
import { toast } from "sonner";

const SendOTPButton = ({ setShowOtp, mobileNo, setOtpToast }) => {
  function handleClick() {
    if (mobileNo.length < 10) {
      toast("Mobile Number should be of 10 digits");
    }
    if (mobileNo.length === 10) {
      setShowOtp(true);
      setOtpToast(true);
    }
  }

  return (
    <div className="flex items-center justify-center my-7">
      <button
        className="px-5 py-3 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg hover:-translate-y-1 hover:shadow-2xl transition-all w-[27rem]"
        onClick={handleClick}
      >
        <span className="drop-shadow-md">Send OTP</span>
      </button>
    </div>
  );
};

export default SendOTPButton;
