import React from "react";
import Lottie from "lottie-react";
import AnimationData from "../../assets/loadingLottie.json";

const LoadingLottie = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="w-[20rem]">
        <Lottie animationData={AnimationData} />
      </div>
      <div
        className="text-[#130C40] text-3xl font-bold mt-10 w-[20rem] text-center"
        style={{ fontFamily: "DM Sans" }}
      ></div>
    </div>
  );
};

export default LoadingLottie;
