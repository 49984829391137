import React, { useState } from "react";
import SectionHeading from "./SectionHeading";
import { LANDING_PAGE_STUDENT_FEEDBACK } from "../../constants/text_constants";
import StudentFeedbackCard from "./StudentFeedbackCard";

const StudentFeedback = ({ feedback }) => {
  const [show, setShow] = useState(false);

  if(!feedback || feedback.length === 0) return;

  const displayCards = feedback.slice(0, 3);
  const nextDisplayCards = feedback.slice(3, 10);

  return (
    <div className="-mx-[10rem] bg-[#F8FCFD] my-[6rem] p-10">
      <SectionHeading
        heading={LANDING_PAGE_STUDENT_FEEDBACK?.heading}
        subHeading={LANDING_PAGE_STUDENT_FEEDBACK?.subHeading}
        headingColor={"#130C40"}
      />
      <div className="flex justify-center items-center -mt-[2rem]">
        <div className="grid grid-cols-3 gap-[1rem]">
          {displayCards.map((ele) => (
            <StudentFeedbackCard {...ele} />
          ))}
          {show &&
            nextDisplayCards.map((ele) => <StudentFeedbackCard {...ele} />)}
        </div>
      </div>
      <div className="flex items-center justify-center mt-20">
        <button
          className="px-5 py-1 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg hover:-translate-y-1 hover:shadow-2xl transition-all"
          onClick={() => setShow(!show)}
        >
          <span className="drop-shadow-md">{show ? "Close" : "View All"}</span>
        </button>
      </div>
    </div>
  );
};

export default StudentFeedback;
