import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCitiesCache } from "../configs/cache";

const useCitiesData = (stateId) => {
  const [citiesData, setCitiesData] = useState(null);
  const cache = useSelector((store) => store.cache.citiesCache);
  const dispatch = useDispatch();

  const fetchCitiesData = async () => {
    const response = await fetch(
      process.env.REACT_APP_RANK_PLUS_CITIES + stateId
    );
    const json = await response.json();
    setCitiesData(json?.data?.cities);
    dispatch(setCitiesCache([json?.data?.cities, stateId]));
  };

  useEffect(() => {
    if (cache.stateId) {
      return cache.stateId;
    } else {
      fetchCitiesData();
    }
  }, [stateId]);

  return citiesData;
};

export default useCitiesData;
