import React from "react";
import SectionHeading from "./SectionHeading";
import { LANDING_PAGE_STUDENT_TESTIMONIALS } from "../../constants/text_constants";
import StudentTestimonialsCarousel from "./StudentTestimonialsCarousel";

const StudentTestimonials = ({ testimonials }) => {
  if (!testimonials || testimonials.length === 0) return;
  return (
    <div className="flex flex-col items-center ">
      <div className="mb-5">
        <SectionHeading
          heading={LANDING_PAGE_STUDENT_TESTIMONIALS?.heading}
          headingColor={"#130C40"}
        />
      </div>
      <div className="flex justify-center">
        <StudentTestimonialsCarousel data={testimonials} />
      </div>
    </div>
  );
};

export default StudentTestimonials;
