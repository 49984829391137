import React from "react";

const FormHeader = ({title, subtitle}) => {
  return (
    <div className="text-center">
      <div
        className="text-[#130C40]"
        style={{
          fontWeight: "800",
          fontSize: "45px",
        }}
      >
        {title}
      </div>
      <div className="text-[#3F3D4B]" style={{ fontWeight: "400" }}>
        {subtitle}
      </div>
    </div>
  );
};

export default FormHeader;
