import React from 'react'

const AnnouncementTab = () => {
  return (
    <div>
      Announcement Tab
    </div>
  )
}

export default AnnouncementTab
