import React from "react";
import { Card, CardContent } from "../../components/ui/card";
import { cn } from "../../lib/utils";
import { UserRoundPen } from "lucide-react";
import { stripHtml } from "../../utils/util-functions/stripHTML";
import { stripText } from "../../utils/util-functions/stripText";
import StarRatings from "react-star-ratings";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import { Link } from "react-router-dom";

const CourseCard = ({
  name,
  course_icon,
  subject_count,
  offer_price,
  offer_discount,
  price,
  course_banner,
  lecture_count,
  note_count,
  dpp_count,
  test_count,
  rating,
  faculty_name,
  thumbnail,
  description,
  id,
}) => {
  return (
    <div>
      <Card>
        <CardContent
          style={{ fontFamily: "DM Sans" }}
          className="aspect-square p-0"
        >
          {/* section 1 - course banner*/}
          <div>
            <img src={course_banner} alt={name} />
          </div>
          <div className="px-3">
            {/* Section 2 - faculty img + name & star*/}
            <div className="flex justify-between items-center my-2">
              {/* faculty img + name */}
              <div className="flex justify-between items-center">
                <div>
                  {thumbnail ? (
                    <img
                      src={thumbnail}
                      alt={faculty_name}
                      className="w-10 h-10 rounded-full"
                    />
                  ) : (
                    <UserRoundPen />
                  )}
                </div>
                <div className="ml-2">
                  {faculty_name ? faculty_name : "course-name"}
                </div>
              </div>
              {/* rating */}
              <div className="">
                <StarRatings
                  rating={Math.floor(rating)}
                  starRatedColor="#FCB129"
                  numberOfStars={5}
                  starDimension="1.2rem"
                />
              </div>
            </div>
            {/* Section 3 - course name */}
            <div className="text-xl" style={{ fontWeight: "600" }}>
              {name}
            </div>
            {/* Section 4 - Course Description */}
            <div className="text-sm my-3">
              {stripText(stripHtml(description), 80)}
            </div>
            {/* Section 5 - Metrics */}
            <div className="flex justify-around border-b border-gray-200 pb-2">
              {/* Lecture */}
              <div className="flex flex-col items-center">
                <div className="font-semibold">{lecture_count}+</div>
                <div className="text-[#82808D]">Lectures</div>
              </div>
              {/* Hand Notes */}
              <div className="flex flex-col items-center">
                <div className="font-semibold">{note_count}+</div>
                <div className="text-[#82808D]">Hand Notes</div>
              </div>
              {/* DPP */}
              <div className="flex flex-col items-center">
                <div className="font-semibold">{dpp_count}+</div>
                <div className="text-[#82808D]">DPP</div>
              </div>
              {/* Live tests */}
              <div className="flex flex-col items-center">
                <div className="font-semibold">{test_count}+</div>
                <div className="text-[#82808D]">Live Tests</div>
              </div>
            </div>
            {/* Section 6 - Button and Price */}
            <div className="flex justify-between items-center my-[1rem]">
              {/* Price */}
              <div className="flex items-center gap-1">
                {/* Discount percentage */}
                <div>
                  {offer_discount !== "0" && offer_discount !== null ? (
                    <div className="rounded-l-full rounded-r-full text-white bg-[#5A277C] p-1 w-10 text-xs text-center">
                      {offer_discount}% off
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="flex flex-col">
                  {/* original price */}
                  {offer_discount !== "0" && offer_discount !== null && (
                    <div className="line-through text-gray-500">₹{price}</div>
                  )}
                  {/* offer_price */}
                  <div className="text-[#5A277C] font-bold">
                    ₹
                    {offer_discount !== "0" && offer_discount !== null
                      ? offer_price
                      : price}
                  </div>
                </div>
              </div>
              {/* buttons */}
              <div className="flex gap-3">
                {/* Explore More Button */}

                <div>
                  <Link to={UNPROTECTED_ROUTES?.courseDetails + "/" + id}>
                    <button
                      className="text-[#03B3C3] px-2 py-1 rounded-xl cursor-pointer"
                      style={{
                        backgroundColor: "rgba(30, 203, 219, 0.15)",
                        fontWeight: 700,
                      }}
                    >
                      Explore More
                    </button>
                  </Link>
                </div>
                {/* Buy Now Button */}
                <div>
                  <button
                    className="text-[#5A277C] px-2 py-1 rounded-xl cursor-pointer"
                    style={{
                      backgroundColor: "rgba(90, 39, 124, 0.10)",
                      fontWeight: 700,
                    }}
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            </div>
            <div className="text-white text-[1px]">h</div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CourseCard;
