import React from 'react'

const TestDetails = () => {
  return (
    <div>
      My Test Details
    </div>
  )
}

export default TestDetails
