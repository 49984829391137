import React from "react";
import RankPlusLogo from "../../assets/RankPlus_logo.png";
import RankPlusLogoText from "../../assets/RankPlus_logo_text.png";
import { Link } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const LoginPageRightHeader = () => {
  return (
    <div className="flex items-center mt-4">
      <Link to={UNPROTECTED_ROUTES?.home}>
        <img
          alt="rank-plus-logo"
          src={RankPlusLogo}
          className="w-[60px] h-[64px]"
        />
      </Link>
      <Link to={UNPROTECTED_ROUTES?.home}>
        <img
          alt="rank-plus-logo-text"
          src={RankPlusLogoText}
          className="w-[127px] h-[30px] ml-4"
        />
      </Link>
    </div>
  );
};

export default LoginPageRightHeader;
