import React from "react";
import { stripHtml } from "../../utils/util-functions/stripHTML";
import { stripText } from "../../utils/util-functions/stripText";

const StudentFeedbackCard = ({ student_name, thumbnail, title, rank }) => {
  return (
    <div className = "-my-10">
      {/* hexagon div */}
      <div
        style={{
          width: "150px",
          aspectRatio: "1",
          clipPath:
            "polygon(93.56% 74.55%,50.52% 100%,6.96% 75.45%,6.44% 25.45%,49.48% 0%,93.04% 24.55%)",
          backgroundImage: `url('${thumbnail}')`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className="bg-white relative top-[7rem] left-[4rem] flex flex-col justify-end z-10"
      >
        <div className="-mb-8">
          <div className="bg-[#5A277C] text-center text-white text-sm">
            {student_name}
          </div>
          <div
            className="bg-[#5A277C] mb-9 text-center text-white"
            style={{
              background:
                "linear-gradient(276deg, #6F3199 -0.84%, #1ECBDB 100%)",
              height: "20px",
            }}
          >
            {rank}
          </div>
        </div>
      </div>
      <div className = "relative top-[1.4rem] left-[3rem]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="180"
          height="40"
          viewBox="0 0 270 40"
          fill="none"
        >
          <path d="M0 0H270L262 20L270 40H0L9 20L0 0Z" fill="#5A277C" />
        </svg>
      </div>
      <div className="bg-white border border-[#DFE3EC] w-[18rem] h-[15rem] rounded-lg p-4 flex justify-center items-center">
        {stripText(stripHtml(title), 100)}
      </div>
    </div>
  );
};

export default StudentFeedbackCard;
