import React from "react";

const PageBanner = ({
  title,
  rightImgSrc,
  backgroundGradient,
  backgroundImage,
  fontColor,
  title2,
  component,
  subtitle,
  backgroundPosition
}) => {
  return (
    <div
      style={{
        backgroundImage: `${backgroundGradient}, url('${backgroundImage}')`,
        backgroundSize: "cover",
        backgroundPosition: backgroundPosition,
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "800",
      }}
      className="h-[20rem] flex justify-between items-center px-[10rem]"
    >
      <div style={{ color: fontColor }} className = "w-[32rem]">
        <div className="text-5xl">{title}</div>
        {title2 && <div className="text-4xl mb-[2rem]">{title2}</div>}
        {subtitle && <div className = "text-sm my-[1rem]">{subtitle}</div>}
        {component}
      </div>
      <div>
        <img alt="banner-img" src={rightImgSrc} className="h-[20rem]" />
      </div>
    </div>
  );
};

export default PageBanner;
