import React from "react";
import { Link } from "react-router-dom";

const FooterBottomCol2 = ({ data }) => {
  return (
    <div>
      <div className="font-bold text-[#F9F9F9] flex justify-center">{data?.title}</div>
      <div className = "flex flex-col gap-3 mt-4 items-center">
        {data?.links.map((ele) => (
          <div key={ele?.id} className = "text-[#9591AA]">
            <Link to={ele?.link}>{ele?.title}</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FooterBottomCol2;
