import React, { useState } from "react";
import BooksListHeader from "./BooksListHeader";
import MyBooksList from "./MyBooksList";
import AllBooksList from "./AllBooksList";

const BooksList = () => {
  const [showAllBooks, setShowAllBooks] = useState(true);
  return (
    <div>
      <BooksListHeader
        setShowAllBooks={setShowAllBooks}
        showAllBooks={showAllBooks}
      />
      <div className="mt-[2rem]">
        {showAllBooks && <AllBooksList />}
        {!showAllBooks && <MyBooksList />}
      </div>
    </div>
  );
};

export default BooksList;
