import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_LIMIT } from "../../constants/text_constants";
import { ChevronLeft, ChevronRight } from "lucide-react";
import LoadingLottie from "../Lottie-Components/LoadingLottie";
import { setAllTestsCache } from "../../configs/cache";
import CourseCard from "../Card-Component/CourseCard";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import { current } from "@reduxjs/toolkit";

const AllTestsList = () => {
  const [allTestsData, setAllTestsData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const cache = useSelector((store) => store.cache.allTestsCache);

  const fetchAllTests = async () => {
    const response = await fetch(
      process.env.REACT_APP_RANK_PLUS_ALL_TESTS_DATA + currentPage
    );
    const json = await response.json();
    setAllTestsData(json?.data);
    setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
    dispatch(setAllTestsCache([json?.data, currentPage]));
  };

  useEffect(() => {
    if (cache[currentPage]) {
      setAllTestsData(cache[currentPage]);
      setTotalPages(Math.ceil(cache[currentPage]?.total / PAGE_LIMIT));
    } else {
      fetchAllTests();
    }
  }, [currentPage, cache]);

  if (!allTestsData || allTestsData?.test_packages.length === 0) {
    return <LoadingLottie />;
  }

  return (
    <div>
      <div className="grid grid-cols-2 gap-[2rem]">
        {allTestsData?.test_packages.map((ele) => (
          <CourseCard
            key={ele?.id}
            name={ele?.name}
            offer_price={ele?.offer_price}
            offer_discount={ele?.offer_discount}
            price={ele?.price}
            course_banner={ele?.banner_url}
            title_1={"Duration"}
            value_1={ele?.duration}
            title_2={"Start Date"}
            value_2={ele?.starts_on}
            title_3={"End Date"}
            value_3={ele?.ends_on}
            title_4={"Validity"}
            value_4={ele?.validity}
            rating={ele?.rating}
            faculty_name={ele?.faculty_name}
            thumbnail={ele?.thumbnail}
            description={ele?.description}
            totalRating={ele?.total_rating}
            id={ele?.id}
            exploreMoreLink={UNPROTECTED_ROUTES?.testDetails}
          />
        ))}
      </div>
      <div className="p-10 flex items-center justify-center">
        {currentPage > 0 && (
          <ChevronLeft
            className="mx-2 hover:-translate-x-1 transition-all cursor-pointer"
            onClick={() => setCurrentPage((currentPage) => currentPage - 1)}
          />
        )}

        {[
          ...Array(totalPages)
            .keys()
            .map((p) => (
              <span
                className={
                  "mx-2 cursor-pointer" +
                  (p === currentPage ? " font-bold text-lg" : "")
                }
                onClick={() => setCurrentPage((currentPage) => p)}
              >
                {p + 1}
              </span>
            )),
        ]}
        {currentPage < totalPages - 1 && (
          <ChevronRight
            className="mx-2 hover:translate-x-1 transition cursor-pointer"
            onClick={() => setCurrentPage((currentPage) => currentPage + 1)}
          />
        )}
      </div>
    </div>
  );
};

export default AllTestsList;
