import React from 'react'
import LoginBanner from "../../assets/LoginBanner.png"

const LoginPageLeft = () => {
  return (
    <div>
      <img alt = "login-banner" src = {LoginBanner} className = "h-[47rem] rounded-2xl"/>
    </div>
  )
}

export default LoginPageLeft
      