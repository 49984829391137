import React, {useState} from "react";
import { NAVBAR_PLACEHOLDER } from "../../constants/text_constants";
import { Search } from "lucide-react"


const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <div>
      <div className="flex border border-gray-200 rounded-3xl items-center justify-between ml-10 p-1">
        <input
          type="text"
          className="w-[20rem] mx-2 px-2 py-1 rounded-lg focus:outline-none"
          placeholder={NAVBAR_PLACEHOLDER}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <div className="bg-[#5A277C] text-white w-9 h-9 rounded-full flex justify-center items-center mr-0.5">
          <Search/>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
