import React from "react";
import IPHONE_MOCKUP from "../../assets/iphoneMockup.png";
import TOP_FOOTER_BG from "../../assets/BgOverlayImage.jpeg";
import SectionHeading from "../LandingPage/SectionHeading";
import { TOP_FOOTER } from "../../constants/text_constants";
import ANDROID_QR from "../../assets/androidQr.png";
import PLAYSTORE_BANNER from "../../assets/playStoreLink.png";
import { Link } from "react-router-dom";

const FooterTop = () => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to right, rgba(34, 211, 238, 0.8), rgba(147, 51, 234, 0.8)), url('${TOP_FOOTER_BG}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        fontFamily: "DM Sans",
      }}
      className="rounded-s-full rounded-e-full flex justify-center items-center gap-[10rem] h-[25rem] mx-10"
    >
      <div>
        <img
          alt="android-qr-code"
          src={ANDROID_QR}
          className="h-[10rem] rounded-lg"
        />
      </div>
      <div>
        <img
          src={IPHONE_MOCKUP}
          alt="iphone-mockup"
          className="h-[30rem] relative -top-20"
        />
      </div>
      <div>
        <SectionHeading heading={TOP_FOOTER?.heading} />
        <div className="text-white w-[20rem]">{TOP_FOOTER?.subHeading}</div>
        <Link to={TOP_FOOTER?.playStoreLink} target="_blank">
          <img
            src={PLAYSTORE_BANNER}
            alt="rank-plus-playstore"
            className="h-12 mt-5"
          />
        </Link>
      </div>
    </div>
  );
};

export default FooterTop;
