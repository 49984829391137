import React from 'react'
import LoginPageRight from './LoginPageRight'
import LoginPageLeft from './LoginPageLeft'

const LoginPage = () => {
  return (
    <div className = "p-4 flex gap-10">
      <LoginPageLeft/>
      <LoginPageRight/>
    </div>
  )
}

export default LoginPage
