import React from "react";
import StarRatings from "react-star-ratings";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import { Link } from "react-router-dom";

const TestCard = ({
  name,
  test_count,
  offer_price,
  offer_discount,
  price,
  rating,
  banner_url,
  id,
}) => {
  return (
    <div
      className="my-2 flex border border-gray-200 p-2 rounded-lg gap-3"
      style={{ fontFamily: "DM Sans" }}
    >
      {/* left - img */}
      <div>
        <img alt={name} src={banner_url} className="w-[13rem] h-[10rem]" />
      </div>
      {/* right - desc */}
      <div className="flex flex-col gap-1 justify-around">
        {/* rating */}
        <div>
          <StarRatings
            rating={Math.floor(rating)}
            starRatedColor="#FCB129"
            numberOfStars={5}
            starDimension="1.1rem"
          />
        </div>
        {/* test count */}
        <div className="my-1">
          <span className="font-bold">{test_count} </span>
          Tests included
        </div>
        {/* Test Name */}
        <div className="font-bold">{name}</div>
        {/* Price and Button */}
        <div className="flex justify-between items-center gap-3">
          <div className="flex items-center gap-1">
            {/* Discount percentage */}
            <div>
              {offer_discount !== "0" && offer_discount !== null ? (
                <div className="rounded-l-full rounded-r-full text-white bg-[#5A277C] p-1 w-10 text-xs text-center">
                  {offer_discount}% off
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="flex flex-col">
              {/* original price */}
              {offer_discount !== "0" && offer_discount !== null && (
                <div className="line-through text-gray-500">₹{price}</div>
              )}
              {/* offer_price */}
              <div className="text-[#5A277C] font-bold">
                ₹
                {offer_discount !== "0" && offer_discount !== null
                  ? offer_price
                  : price}
              </div>
            </div>
          </div>
          <div>
            <Link to={UNPROTECTED_ROUTES?.testDetails + "/" + id}>
              <button
                className="text-[#03B3C3] px-2 py-1 rounded-xl cursor-pointer"
                style={{
                  backgroundColor: "rgba(30, 203, 219, 0.15)",
                  fontWeight: 700,
                }}
              >
                Explore More
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestCard;
