import React from 'react'

const TestSeriesTab = () => {
  return (
    <div>
      Test Series Tab
    </div>
  )
}

export default TestSeriesTab
