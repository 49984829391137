import React from 'react'

const ClassroomTab = () => {
  return (
    <div>
      Classroom Tab
    </div>
  )
}

export default ClassroomTab
