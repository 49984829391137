import React from "react";
import { UserRoundMinus } from "lucide-react";
import { useSelector } from "react-redux";
import PhoneIcon from "../../assets/Phone.png";
import EnvelopeIcon from "../../assets/Envelope.png";
import EditUserDetails from "./EditUserDetails";
import WalletIcon from "../../assets/Wallet.png";

const UserDetails = () => {
  const user = useSelector((store) => store.user);

  return (
    <div className="bg-[#F8FCFD] flex flex-col justify-center items-center relative">
      <div className="p-2 rounded-full bg-white absolute -top-10">
        {user.userIcon ? "" : <UserRoundMinus className="w-[4rem] h-[4rem]" />}
      </div>
      <div className="mt-20 flex justify-around items-center gap-[5rem] mb-10">
        {/* Phone Number */}
        <div>
          <div className=" flex items-center justify-center gap-2 text-[#82808D]">
            <div>
              <img alt="phone-icon" src={PhoneIcon} />
            </div>
            <div> Phone:</div>
          </div>
          <div className="mt-2">{user?.phoneNo}</div>
        </div>
        <div>
          <div className="flex items-center justify-center gap-2 text-[#82808D]">
            <div>
              <img alt="envelop-icon" src={EnvelopeIcon} />
            </div>
            <div> Email:</div>
          </div>
          <div className="mt-2">{user?.email}</div>
        </div>
        <div>
          <div className="flex items-center justify-center gap-2 text-[#82808D]">
            <div>
              <img alt="wallet-icon" src={WalletIcon} />
            </div>
            <div>My Wallet Points:</div>
          </div>
          <div className="mt-2 text-[#1ECBDB] font-semibold">₹ {user?.walletPoints}</div>
        </div>
        <div>
          <EditUserDetails />
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
