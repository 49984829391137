import React from "react";
import PageBanner from "../PageBanner/PageBanner";
import { TEST_SERIES_PAGE_BANNER, COURSES_PAGE_SHOW_TESTS_FILTER } from "../../constants/text_constants";
import BANNER_IMG from "../../assets/GirlWithLaptop.png";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import TestsList from "./TestsList";

const TestSeriesPage = () => {
  return (
    <div>
      <PageBanner
        title={TEST_SERIES_PAGE_BANNER?.title1}
        subtitle={TEST_SERIES_PAGE_BANNER?.subtitle}
        rightImgSrc={BANNER_IMG}
        backgroundGradient={
          "linear-gradient(to right, rgba(254, 246, 250, 0.9), rgba(225, 234, 252, 0.9))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#130C40"}
      />
      <div className="mx-[10rem] flex gap-[2rem] justify-around my-[3rem]">
        {COURSES_PAGE_SHOW_TESTS_FILTER && (
          <div className="bg-green-300 w-1/4">filter</div>
        )}
        <div className="w-3/4">
          <TestsList />
        </div>
      </div>
    </div>
  );
};

export default TestSeriesPage;
