import React from "react";
import Herocards from "./Herocards";
import RevolutionLearning from "./RevolutionLearning";
import MetricsSection from "./MetricsSection";
import FeaturesSection from "./FeaturesSection";
import useLandingPageData from "../../utils/use-LandingPageData";
import CoursesAvailableSection from "./CoursesAvailableSection";

// temp exports
import StudentTestimonials from "./StudentTestimonials";
import StudentFeedback from "./StudentFeedback";
import HeroBanner from "./HeroBanner";
import PreperationSection from "./PreperationSection";

const LandingPage = () => {
  const landingPageData = useLandingPageData();

  return (
    <div>
      <HeroBanner />
      <div className="px-[5rem] overflow-x-hidden overflow-y-hidden">
        <Herocards />
        <CoursesAvailableSection data={landingPageData?.data?.courses} />
        <PreperationSection
          tests={landingPageData?.data?.test_packages}
          books={landingPageData?.data?.books}
        />
        <RevolutionLearning />
        <MetricsSection />
        <StudentFeedback feedback={landingPageData?.data?.selected_students} />
        <FeaturesSection />
        <StudentTestimonials
          testimonials={landingPageData?.data?.testimonials}
        />
      </div>
    </div>
  );
};

export default LandingPage;
