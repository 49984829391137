import React from "react";
import { LANDING_PAGE_PREPERATION_SECTION_BOOKS } from "../../constants/text_constants";
import SectionHeading from "./SectionHeading";
import { ScrollArea } from "../../components/ui/scroll-area";
import BookCard from "./BookCard";
import { Link } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const PreperationSectionBooks = ({ books }) => {
  return (
    <div className="flex flex-col items-center mt-[2rem]">
      <SectionHeading
        heading={LANDING_PAGE_PREPERATION_SECTION_BOOKS?.heading}
        subHeading={LANDING_PAGE_PREPERATION_SECTION_BOOKS?.subHeading}
        headingColor={"#130C40"}
      />
      <div className="border border-gray-200 h-[40rem] w-[30rem] rounded-md p-4 overflow-y-auto grid grid-cols-2 gap-2">
        {books.map((ele) => (
          <BookCard key={ele?.id} {...ele} />
        ))}
      </div>
      <div className="flex justify-center items-center mt-16">
        <Link to={UNPROTECTED_ROUTES?.books}>
          <button className="px-5 py-1 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg hover:-translate-y-1 hover:shadow-2xl transition-all">
            <span className="drop-shadow-md">View All</span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default PreperationSectionBooks;
