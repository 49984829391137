import { useState, useEffect } from "react";

const useCourseDescription = (courseId) => {
  const [courseDescription, setCourseDescription] = useState(null);
  const fetchCourseDescription = async () => {
    try {
      const requestOptions = {
        method: "GET",
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_COURSE_DETAILS_DESCRIPTION.replace(
          /COURSE_ID/,
          courseId
        ),
        requestOptions
      );
      const json = await response.json();
      setCourseDescription(json?.data);
    } catch (err) {
      console.log("Error fetchCourseDescription", err);
    }
  };

  useEffect(() => {
    fetchCourseDescription();
  }, [courseId]);

  return courseDescription;
};

export default useCourseDescription;
